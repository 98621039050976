import { takeEvery, put } from "redux-saga/effects";
import * as Admin from '../slices/admin.slice';
import { types } from '@proj/static';
import * as adminServices from '../../services/admin.services';

function* getAdminUsers() {
    try {
        let result: types.userTypes.TAdmin[] = yield (adminServices.getAdminUsers());
        yield put(Admin.getAdminUsersSuccess(result));
    }
    catch (err) {
        yield put(Admin.getAdminUsersFailed({
            error: err,
            message: "Error Occured"
        }))
    }
}

function* adminSaga() {
    yield takeEvery(Admin.getAdminUsers.type, getAdminUsers);
}
export default adminSaga;