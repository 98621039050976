
export default function Home() {
  return (
    <div className='flex align-items-center h-full justify-center flex-col' style={{ margin: '-30px', paddingRight: '30px', background: '#fff' }}>
      <div className="demo-logo-vertical" style={{ width: "100%", textAlign: "center", padding: "40px 0px" }}>
        <img src="/images/logo.png" alt="ByNature" width="250" height="auto" />
      </div>
      <h2>Welcome to ByNature ordering portal</h2>
    </div>
  )
}
