import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'

// slices or reducers
import auth from './slices/auth.slice';
import user from './slices/user.slice';
import client from './slices/client.slice';
import Order from './slices/order.slice';
import Common from './slices/common.slice';
import country from './slices/country.slice';
import state from './slices/state.slice';
import admin from './slices/admin.slice';

// sagas
import authSaga from './sagas/auth.saga';
import userSaga from './sagas/user.saga';
import clientSaga from './sagas/client.saga';
import OrderSaga from './sagas/order.saga';
import countrySaga from './sagas/country.saga';
import staeSaga from './sagas/state.saga';
import adminSaga from './sagas/admin.saga';


const saga = createSagaMiddleware();
export const store = configureStore({
    reducer: {
        auth: auth,
        user: user,
        client: client,
        order: Order,
        common: Common,
        country: country,
        state: state,
        admin: admin

    },
    middleware: [saga]
})

saga.run(authSaga);
saga.run(userSaga);
saga.run(clientSaga);
saga.run(OrderSaga);
saga.run(countrySaga);
saga.run(staeSaga);
saga.run(adminSaga);



export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch