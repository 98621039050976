import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { types } from '@proj/static';

type adminUsers = {
    adminusers: types.userTypes.TAdmin[],
    isLoading: boolean
}

const defaultState: adminUsers = {
    adminusers: [],
    isLoading: true,
}


export const AdminUsers = createSlice({
    name: "adminusers",
    initialState: defaultState,
    reducers: {
        getAdminUsers: (state) => {
            return {
                ...state,
                isLoading: true
            }
        },
        getAdminUsersSuccess: (state, action: PayloadAction<types.userTypes.TAdmin[]>) => {
            return {
                ...state,
                adminusers: action.payload
            }
        },
        getAdminUsersFailed: (state, action: PayloadAction<types.commonTypes.HTTP_ERROR>) => {
            return {
                ...state,
                getAdminUsersError: action.payload
            }
        },
    }
})

export const {
    getAdminUsers,
    getAdminUsersSuccess,
    getAdminUsersFailed
} = AdminUsers.actions;

export default AdminUsers.reducer;
